import './App.less';

import PageLoader from 'components/shared/PageLoader';
import BaseController from 'controllers/BaseController';
import { observer } from 'mobx-react-lite';
import React, { FC, PropsWithChildren } from 'react';
import { BrowserRouter, BrowserRouterProps } from 'react-router-dom';

const Router = BrowserRouter as unknown as FC<PropsWithChildren<BrowserRouterProps>>;

const App = observer(() => (
  <Router>
    <PageLoader />
    <BaseController />
  </Router>
));

export default App;
