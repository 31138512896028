import { TemplateChannelEnum, TemplateLocaleEnum } from '__generated__/types';
import { EAttachmentStatus } from '@xometry/ui';
import { FormInstance } from 'antd';
import {
  StateAttachment,
  TemplateModalFormFieldEnum,
  TemplateModalFormValues,
} from 'components/TemplatesModal/TemplateModal.types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'stores/RootStore';
import { TemplateFragment } from 'utils/graphql/fragments/__generated__/template';
import { UserFragmentFragment } from 'utils/graphql/fragments/__generated__/userFragment';
import { useRenderedTemplateQuery } from 'utils/graphql/queries/__generated__/renderedTemplate';
import { TemplateModalQuery } from 'utils/graphql/queries/__generated__/templateModal';
import { useUsers } from 'utils/hooks/useUsers';

interface Args {
  form: FormInstance<TemplateModalFormValues>;
  templateModalData: TemplateModalQuery;
  selectedCategoryId: string | null;
  setSelectedCategoryId: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTemplate: TemplateFragment | null;
  setSelectedTemplate: React.Dispatch<React.SetStateAction<TemplateFragment | null>>;
}

export const useTemplatesModalForm = ({
  form,
  templateModalData,
  selectedCategoryId,
  setSelectedCategoryId,
  selectedTemplate,
  setSelectedTemplate,
}: Args) => {
  const [emailFieldsVisible, setEmailFieldsVisible] = useState(false);
  const [attachments, setAttachments] = useState<StateAttachment[]>([]);

  const { templatesModalStore } = useStore();
  const { getUserById } = useUsers();

  const { data: renderedTemplateData, loading: renderedTemplateLoading } = useRenderedTemplateQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: selectedTemplate?.id as string,
      sourceId: templatesModalStore.messageSourceForTemplates?.sourceId as string,
      sourceType: templatesModalStore.messageSourceForTemplates?.sourceType as string,
    },
    skip: !selectedTemplate || !templatesModalStore.messageSourceForTemplates?.sourceId,
    onCompleted: (data) => {
      if (!data.messengerRenderTemplate) {
        return;
      }

      const { content, recipients = [], carbonCopy = [], blindCarbonCopy = [] } = data.messengerRenderTemplate;

      form.setFieldValue(TemplateModalFormFieldEnum.To, recipients);
      form.setFieldValue(TemplateModalFormFieldEnum.Cc, carbonCopy);
      form.setFieldValue(TemplateModalFormFieldEnum.Bcc, blindCarbonCopy);
      form.setFieldValue(TemplateModalFormFieldEnum.Content, content);

      // Uses PREVIEW_SUCCESS because it's a virtual file, it's only needed as a placeholder.
      // After sending the template, this file is created on the server.
      const prepareAttachments = data?.messengerRenderTemplate?.prepareAttachments.map((prepareAttachment) => ({
        id: prepareAttachment.uuid,
        uuidId: prepareAttachment.uuid,
        status: EAttachmentStatus.PREVIEW_SUCCESS,
        ...prepareAttachment,
      }));

      const initialAttachments = [
        ...(data?.messengerRenderTemplate?.attachments as StateAttachment[]),
        ...(data?.messengerRenderTemplate?.documents as StateAttachment[]),
        ...prepareAttachments,
      ];

      setAttachments((attachments) => [...initialAttachments, ...attachments]);
    },
  });

  const handleTemplateSelect = useCallback(
    (template: TemplateFragment | undefined) => {
      form.setFieldValue(TemplateModalFormFieldEnum.To, []);
      form.setFieldValue(TemplateModalFormFieldEnum.Cc, []);
      form.setFieldValue(TemplateModalFormFieldEnum.Bcc, []);
      form.setFieldValue(TemplateModalFormFieldEnum.Content, '');

      setAttachments([]);
      setSelectedTemplate(template || null);
    },
    [form, setSelectedTemplate],
  );

  const { category, isEmailSendingAvailable, isUploadingFilesAvailable } = useMemo(() => {
    const category = templateModalData.messengerTemplateCategories.find(({ id }) => selectedCategoryId === id);

    return {
      category,
      isEmailSendingAvailable: category?.channels.includes(TemplateChannelEnum.Email),
      isUploadingFilesAvailable: !category?.channels.includes(TemplateChannelEnum.Whatsapp),
    };
  }, [selectedCategoryId, templateModalData?.messengerTemplateCategories]);

  const templateMapByConstantName = useMemo(() => {
    const templateMapByConstantName: Record<string, Partial<Record<TemplateLocaleEnum, TemplateFragment>>> = {};

    category?.templatesGroups.forEach(({ constantName, name, templates }) => {
      if (!constantName) {
        return;
      }

      const localesMap: Partial<Record<TemplateLocaleEnum, TemplateFragment>> = templates.reduce(
        (prev, { id, locale }) => ({ ...prev, [locale]: { constantName, name, locale, id } }),
        {},
      );

      templateMapByConstantName[constantName] = localesMap;
    });

    return templateMapByConstantName;
  }, [category?.templatesGroups]);

  let clientOrPartnerLocale = '';

  if (templatesModalStore.messageVisibilityAttributes?.isVisibleToClient) {
    clientOrPartnerLocale = templateModalData.messengerSourceRelatedObjects?.person?.locale?.toLocaleUpperCase() || '';
  } else if (templatesModalStore.messageVisibilityAttributes?.isVisibleToPartner) {
    clientOrPartnerLocale =
      templateModalData.messengerSourceRelatedObjects?.provider?.locale?.toLocaleUpperCase() || '';
  }

  const localeOptions = Object.keys(
    templateMapByConstantName[form.getFieldValue('templateConstantName') as string] || {},
  ).map((locale) => ({ value: locale, label: locale.toLocaleUpperCase() }));

  /**
   * Handles the changing of a category.
   */
  const handleCategoryChange = useCallback(
    (categoryId: string) => {
      setSelectedCategoryId(categoryId);
    },
    [setSelectedCategoryId],
  );

  /**
   * Handles the setting of a template's constant name.
   */
  const handleTemplateConstantNameChange = useCallback(
    (newTemplateConstantName: string) => {
      form.setFieldValue(TemplateModalFormFieldEnum.TemplateConstantName, newTemplateConstantName);
      const localesOfTemplate = templateMapByConstantName[newTemplateConstantName];

      if (localesOfTemplate) {
        const hasClientOrPartnerLocale = Object.keys(localesOfTemplate).includes(clientOrPartnerLocale);

        const currentLocale = hasClientOrPartnerLocale
          ? clientOrPartnerLocale
          : (form.getFieldValue(TemplateModalFormFieldEnum.Locale) as TemplateLocaleEnum);

        form.setFieldValue(
          TemplateModalFormFieldEnum.Locale,
          localesOfTemplate[currentLocale as TemplateLocaleEnum] ? currentLocale : Object.keys(localesOfTemplate)[0],
        );

        const newTemplate =
          templateMapByConstantName[form.getFieldValue(TemplateModalFormFieldEnum.TemplateConstantName) as string][
            form.getFieldValue(TemplateModalFormFieldEnum.Locale) as TemplateLocaleEnum
          ];

        handleTemplateSelect(newTemplate);
      }
    },
    [clientOrPartnerLocale, form, handleTemplateSelect, templateMapByConstantName],
  );

  const handleLocaleChange = useCallback(
    (locale: string) => {
      const templateConstantName = form.getFieldValue(TemplateModalFormFieldEnum.TemplateConstantName) as string;
      const newTemplate = templateMapByConstantName[templateConstantName]?.[locale as TemplateLocaleEnum];

      handleTemplateSelect(newTemplate);
    },
    [form, handleTemplateSelect, templateMapByConstantName],
  );

  /**
   * Setting of the first template from new category.
   */
  useEffect(() => {
    const firstConstantName = category?.templatesGroups[0]?.constantName;

    if (firstConstantName) {
      handleTemplateConstantNameChange(firstConstantName);
    }
  }, [category?.templatesGroups, handleTemplateConstantNameChange]);

  const handleMentionSelect = useCallback(
    (userId: UserFragmentFragment['id']) => {
      const curMentions = form.getFieldValue(TemplateModalFormFieldEnum.Mentions) as string[] | undefined;
      const newMentions = [...(curMentions || []), String(userId)];

      form.setFieldValue(TemplateModalFormFieldEnum.Mentions, newMentions);
    },
    [form],
  );

  const handleMentionDeselect = useCallback(
    (userId: number) => {
      const curMentions = form.getFieldValue(TemplateModalFormFieldEnum.Mentions) as string[];
      const newMentions = curMentions.filter((id) => id !== String(userId));

      form.setFieldValue(TemplateModalFormFieldEnum.Mentions, newMentions);
    },
    [form],
  );

  const handleToChange = (value: string[]) => {
    form.setFieldValue(TemplateModalFormFieldEnum.To, value);
  };

  const handleCcChange = (value: string[]) => {
    form.setFieldValue(TemplateModalFormFieldEnum.Cc, value);
  };

  const handleBccChange = (value: string[]) => {
    form.setFieldValue(TemplateModalFormFieldEnum.Bcc, value);
  };

  /**
   * Initial setting up of the first category.
   */
  useEffect(() => {
    if (templateModalData) {
      const newCategoryId = templateModalData.messengerTemplateCategories[0]?.id;

      form.setFieldValue(TemplateModalFormFieldEnum.Category, newCategoryId);
      handleCategoryChange(newCategoryId);
    }
  }, [form, handleCategoryChange, templateModalData]);

  return {
    emailFieldsVisible,
    setEmailFieldsVisible,
    getUserById,
    templateMapByConstantName,
    renderedTemplateData,
    renderedTemplateLoading,
    isEmailSendingAvailable,
    isUploadingFilesAvailable,
    localeOptions,
    attachments,
    setAttachments,
    handleLocaleChange,
    handleMentionSelect,
    handleMentionDeselect,
    handleToChange,
    handleCcChange,
    handleBccChange,
    handleCategoryChange,
    handleTemplateConstantNameChange,
  };
};
