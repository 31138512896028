import { Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross.svg';
import clsx from 'clsx';
import { Modal } from 'components/UI';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { TemplateFragment } from 'utils/graphql/fragments/__generated__/template';
import { DeviceTypes, useDeviceType } from 'utils/hooks/useDeviceType';

import { ChannelMarker } from './ChannelMarker/ChannelMarker';
import { TemplateModalFormValues } from './TemplateModal.types';
import s from './TemplatesModal.module.less';
import { TemplatesModalForm } from './TemplatesModalForm/TemplatesModalForm';
import { useTemplatesModal } from './useTemplatesModal';

const TemplatesModalComponent: React.FC = () => {
  const [form] = useForm<TemplateModalFormValues>();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateFragment | null>(null);
  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  const {
    templateModalData,
    handleCloseTemplatesModal,
    handleSendMessage,
    messageCreateLoading,
    templatesModalVisibility,
    isVisibleToClient,
    isVisibleToPartner,
  } = useTemplatesModal({ form, selectedTemplate });

  const { channels } = useMemo(() => {
    const category = templateModalData?.messengerTemplateCategories.find(({ id }) => selectedCategoryId === id);

    return {
      channels: category?.channels,
    };
  }, [selectedCategoryId, templateModalData?.messengerTemplateCategories]);

  const modalHeader = () => (
    <div className={s.mobileHeader}>
      <span>Message Center Templates</span> <CrossIcon onClick={handleCloseTemplatesModal} />
    </div>
  );

  const mods = { [s.isMobile]: isMobile };

  return (
    <Modal
      className={clsx(s.modal, { ...mods })}
      title={isMobile ? modalHeader() : 'Message Center Templates'}
      open={templatesModalVisibility}
      onCancel={handleCloseTemplatesModal}
      width={1000}
      footer={
        <div className={clsx(s.footer, { ...mods })}>
          <div key="channels" className={clsx(s.channels, { ...mods })}>
            {channels?.map((channel) => {
              return <ChannelMarker key={channel} channel={channel} />;
            })}
            {isVisibleToClient ? <span className={s.visibleToCustomer}>Visible to Customer</span> : null}
            {isVisibleToPartner ? <span className={s.visibleToPartner}>Visible to Partner</span> : null}
          </div>
          <Button key="send" type="primary" loading={messageCreateLoading} onClick={handleSendMessage}>
            Send Message
          </Button>
        </div>
      }
      isMobile={isMobile}
      destroyOnClose
      centered
    >
      {templateModalData ? (
        <TemplatesModalForm
          form={form}
          isMobile={isMobile}
          templateModalData={templateModalData}
          selectedCategoryId={selectedCategoryId}
          setSelectedCategoryId={setSelectedCategoryId}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          isVisibleToExternals={isVisibleToClient || isVisibleToPartner}
        />
      ) : null}
    </Modal>
  );
};

export const TemplatesModal = observer(TemplatesModalComponent);
