import { FormInstance, Modal } from 'antd';
import { EditPartTabKey } from 'components/Deal/Parts/Edit/Form';
import { Part, PartFormValues, TagContexts } from 'interfaces';
import { isEmpty, uniqBy } from 'lodash-es';
import { action, computed, observable } from 'mobx';

import PartsStore from './PartsStore';

const { confirm } = Modal;

export class EditPartModalStore {
  partsStore: PartsStore;

  constructor(partsStore: PartsStore) {
    this.partsStore = partsStore;

    this.openPartModal = this.openPartModal.bind(this);
    this.closePartModal = this.closePartModal.bind(this);
  }

  @observable currentPart?: Part = undefined;

  @observable shouldOpenNextPart = true;

  // Scroll to the part after close the modal.
  @observable partIdForScrollTo?: number = undefined;

  @observable activeTab: EditPartTabKey | undefined = undefined;

  @computed get isPartModalOpen() {
    return !!this.currentPart;
  }

  @computed get currentPartIndex() {
    const partsList = this.partsStore.partsList;

    return partsList?.findIndex((part) => part.id === this.currentPart?.id);
  }

  @computed get nextPart() {
    const partsList = this.partsStore.partsList;
    const partIndex = this.currentPartIndex;

    if (partIndex !== undefined && partIndex !== -1) {
      return partsList?.[partIndex + 1];
    }
  }

  @computed get disableNextPartButton() {
    return !this.nextPart;
  }

  form: FormInstance<PartFormValues> | undefined = undefined;

  setForm = (form?: FormInstance<PartFormValues>) => {
    this.form = form;
  };

  isFormDirty = () => {
    return this.form?.isFieldsTouched();
  };

  get initialValues(): Partial<PartFormValues> {
    const part = this.currentPart;

    if (!part) {
      return {};
    }

    const uniqueTags = uniqBy(part.tags, 'id');

    return {
      // Audit and Drawings form values
      drawingReworkTask: part.drawingReworkTask,
      drawingsReworkedBy: part.drawingsReworkedBy?.email,
      drawingsReworkStatus: part.drawingsReworkStatus,

      // Edit Part Details and Drawings form values
      comments: part.comments,
      isPartVerChange: false,
      versionDesc: '',

      // Audit form values
      auditComment: part.auditComment,
      auditState: part.auditState,
      auditUpdatedBy: part.auditUpdatedBy?.email,
      auditUpdatedAt: part.auditUpdatedAt,
      hsCode: part.hsCode,
      hsCodeName: part.hsCodeName,
      hsCodePurpose: part.hsCodePurpose || '',
      hsCodeState: part.hsCodeState,
      hsCodeConfirmedByName: part.hsCodeConfirmedBy?.label,
      hsCodeConfirmedAt: part.hsCodeConfirmedAt,

      productionMethodsTags: uniqueTags
        .filter((tag) => tag.context === TagContexts.ProductionMethods)
        .map((tag) => tag.id),
      productionMethodsTagsOption: uniqueTags
        .filter((tag) => tag.context === TagContexts.ProductionMethods)
        .map((tag) => ({ value: tag.id, label: tag.name })),

      productionMethodFeaturesTags: uniqueTags
        .filter((tag) => tag.context === TagContexts.ProductionMethodFeatures)
        .map((tag) => tag.id),
      productionMethodFeaturesTagsOption: uniqueTags
        .filter((tag) => tag.context === TagContexts.ProductionMethodFeatures)
        .map((tag) => ({ value: tag.id, label: tag.name })),

      materialsTags: uniqueTags.filter((tag) => tag.context === TagContexts.Materials).map((tag) => tag.id),
      materialsTagsOption: uniqueTags
        .filter((tag) => tag.context === TagContexts.Materials)
        .map((tag) => ({ value: tag.id, label: tag.name })),

      productionRisksTags: uniqueTags.filter((tag) => tag.context === TagContexts.ProductionRisks).map((tag) => tag.id),
      productionRisksTagsOption: uniqueTags
        .filter((tag) => tag.context === TagContexts.ProductionRisks)
        .map((tag) => ({ value: tag.id, label: tag.name })),

      // Drawing form values
      isDrawingsReworkNotDone: isEmpty(part.drawingReworkTask),

      // Edit Part Details form values
      position: part.position,
      name: part.name,
      material: part.material,
      finish: part.postProcessing,
      weightKg: part.weightKg,
      dimensions: part.dimensions,
      customerNote: part.customerNote,
      productionRemark: part.productionRemark,
      isNoQuote: part.isNoQuote,
      isExpress: part.isExpress,
      isMpNeeded: !!part.isMeasurementProtocolNeeded,
      mpNeeded: part.isMeasurementProtocolNeeded,
      isSamplesNeeded: part.isSamplesNeeded,
      samplesQuantity: part.samplesQuantity,
      samplesComment: part.samplesComment,
      isPreQuoted: part.isPreQuoted,
      preQuotedByOption: part.preQuotedBy || undefined,
      preQuotedBy: part.preQuotedBy?.value,
      preQuotedComment: part.preQuotedComment,
    };
  }

  @action openPartModal(part: Part) {
    this.currentPart = part;

    if (!this.nextPart) {
      this.setShouldOpenNextPart(false);
    }

    this.partsStore.reset();
    this.partsStore.setEditDrawingsPartAttachments([...part.attachmentFiles]);
  }

  @action openPartModalWithTab(part: Part, tab: EditPartTabKey) {
    this.openPartModal(part);
    this.activeTab = tab;
  }

  @action private forceClosePartModal() {
    this.currentPart = undefined;
    this.setShouldOpenNextPart(true);
    this.scrollToPartAndOpenPanel();
    this.partsStore.reset();
    this.setActiveTab(undefined);
  }

  @action closePartModal() {
    if (this.isFormDirty()) {
      confirm({
        title: 'Close edit part dialog?',
        content: 'Changes you made may not be saved',
        onOk: () => {
          this.forceClosePartModal();
        },
        onCancel: () => {
          return;
        },
      });
    } else {
      this.forceClosePartModal();
    }
  }

  @action setActiveTab = (tab?: EditPartTabKey) => {
    this.activeTab = tab;
  };

  @action scrollToPartAndOpenPanel() {
    if (this.partIdForScrollTo) {
      this.partsStore.openPanel(String(this.partIdForScrollTo));
      this.scrollToPart(this.partIdForScrollTo);
      // Reset the part id for scroll to.
      this.partIdForScrollTo = undefined;
    }
  }

  @action scrollToPart(partId: number) {
    const partElement = document.getElementById(`part_id_${partId}`);

    if (partElement) {
      partElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  @action setShouldOpenNextPart = (value: boolean) => {
    this.shouldOpenNextPart = value;
  };

  @action openNextPartModal = (): void => {
    if (this.shouldOpenNextPart) {
      const nextPart = this.nextPart;

      this.partIdForScrollTo = nextPart?.id;

      if (nextPart) {
        // Close to reset form values and modal state.
        this.currentPart = undefined;
        // Wait for unmount form in modal.
        setTimeout(() => {
          this.openPartModal(nextPart);
        }, 200);

        return;
      }

      return this.forceClosePartModal();
    }

    return this.forceClosePartModal();
  };
}
