import {
  Money,
  ShippingAutoOrderStateEnum,
  ShippingDeliveryBasisEnum,
  ShippingDeliveryTypeEnum,
  ShippingParameters,
  ShippingPositionNamingEnum,
  WarehouseInstructionEnum,
} from '__generated__/types';
import { IMoney } from '@xometry/ui';
import { ShippingDirection } from 'collections/shippingConstants';
import { HSCodeState } from 'components/Deal/shared/PartHsCodeForm/PartHsCodeForm.types';
import { MeasurementProtocols } from 'interfaces';
import { ICurrentlyViewing, ObjectType, PartData } from 'interfaces/shared';
import { IProviderOrder } from 'interfaces/stores/DealProviderOrderStore';
import { StorageType } from 'interfaces/stores/StorageStore';

import { IAttachedFile } from './AttachedFilesStore';

export interface IIndexable<T> {
  [key: string]: T | undefined;
}

export enum CustomsDocumentsStatus {
  NotRequired = 'not_required',
  New = 'new',
  InProgress = 'in_progress',
  Completed = 'completed',
}

export const customsDocumentsStatusLabels: Record<CustomsDocumentsStatus, string> = {
  [CustomsDocumentsStatus.NotRequired]: 'Not required',
  [CustomsDocumentsStatus.New]: 'New',
  [CustomsDocumentsStatus.InProgress]: 'In progress',
  [CustomsDocumentsStatus.Completed]: 'Completed',
};

export interface IAdditionalService {
  _key?: string;
  _editable?: true;
  id?: number;
  position?: number | null;
  name: string;
  typeName: string;
  value: number;
  valueMoney?: IMoney;
  vatRate?: number | null;
}
export interface IAddress {
  id?: string | number | null;
  address?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  company?: string | null;
  phone?: string | null;
  email?: string | null;
  name?: string | null;
  providerId?: string | null;
  sourceType?: string | null;
  sourceId?: string | null;
  comment?: string | null;
  internalComment?: string | null;
}

export interface IDealLot {
  id: number;
  partId: number;
  partName: string;
  partMaterial: string;
  partPosition: number;
  partPostProcessing: string;
  partCustomerNote?: string;
  partIsExpress?: boolean;
  partIsPreQuote?: boolean;
  partIsSamplesNeeded?: boolean;
  partMpNeeded?: MeasurementProtocols;
  partInternalComment?: string;
  partPreQuotedByLabel?: string;
  partPreQuotedComment?: string;
  partSamplesQuantity?: number;
  partSamplesComment?: string;
  partHsCode?: string;
  partHsCodeName?: string;
  partHsCodeState: HSCodeState | null;
  partHsCodeConfirmedBy: {
    label: string;
    email: string;
  } | null;
  partHsCodeConfirmedAt: Date | null;
  partHsCodePurpose: string | null;
  quantity: number;
  productionRemark?: string;
  customerPrice: number;
  providerPrice: number;
  customerPriceMoney?: Money;
  providerPriceMoney?: Money;
  files: IAttachedFile[];
  isPlaced: boolean;
  isUsed: boolean;
  vatRate: number;
  weightKgPerPiece: number;
}

export interface IDealPart {
  id: number;
  position: number;
}

export type JumingoOrderStateValue = 'loading' | 'loaded' | 'failed';

export interface IJumingoOrderState {
  trackingNumber?: { state: JumingoOrderStateValue; errors?: string[] };
  shippingLabel?: { state: JumingoOrderStateValue; errors?: string[] };
}

export interface IRelatedDocument {
  label: string;
  link: string | undefined;
  canceled: boolean;
}

export interface IRepackForCustomerParams {
  useSameSize: boolean;
}

export interface IRepackForPartnerReturnParams {
  useSameSize: boolean;
}

export interface IShippingLot {
  id: number;
  customerPrice: number;
  customerPriceMoney: Money;
  providerPriceMoney: Money;
  files: IAttachedFile[];
  key: string;
  partId: number;
  part: PartData;
  partName: string;
  providerPrice: number;
  quantity: number;
  vatRate: number;
  weightKgPerPiece?: number;
}

interface SourceDocumentBase {
  sourceId: number;
  sourceName: string;
  sourceComment: string;
  address?: IAddress;
}

export interface ProvideOrderSource extends SourceDocumentBase {
  sourceType: ObjectType.PO;
  shipDirection?: ShippingDirection;
  shipDirectionToEndCustomer?: boolean;
  shipIncoterms?: ShippingDeliveryBasisEnum;
  shipWarehouse?: WarehouseInstructionEnum;
}

export interface ShippingSource extends SourceDocumentBase {
  sourceType: ObjectType.SH;
}

export interface IShippingDimensions {
  deliveryType?: ShippingDeliveryTypeEnum;
  shippingParameters?: ShippingParameters[] | null;
}

export interface IShipping extends IShippingDimensions {
  actualDeliveryDate?: string;
  actualShipmentDate?: string;
  allowDirectShipping: boolean;
  attachedFiles: IAttachedFile[];
  autoOrderState: ShippingAutoOrderStateEnum;
  currentlyViewing: ICurrentlyViewing[];
  customsOfficeCode?: string;
  deliveryBasis?: ShippingDeliveryBasisEnum;
  deliveryNoteComment: string;
  destinationAddress?: IAddress | null;
  direction: ShippingDirection;
  expectedDeliveryDate?: string;
  expectedShipmentDate?: string;
  holdAt: string | null;
  holdBy?: IUser;
  holdById?: string | null;
  holdUntil: string | null;
  id: number;
  isExport: boolean;
  isImport: boolean;
  isImportAndExport: boolean;
  internalComment?: string;
  isPartial: boolean;
  jumingoOrderState?: IJumingoOrderState;
  logisticAggregator?: string;
  logisticOperator?: string;
  lots: IShippingLot[];
  movementReferenceNumber?: string;
  notifyCustomer?: boolean;
  notifyPartner?: boolean;
  number: string;
  xometryNumber?: string | null;
  owner?: IUser;
  packagingReportCheckedAt?: Date;
  packagingReportCheckStatus: string;
  packagingReportCreatedBy?: IUser;
  positionNaming: ShippingPositionNamingEnum;
  warehouseInstruction: WarehouseInstructionEnum | null;
  publishedInCa?: boolean;
  publishedInPa?: boolean;
  readyForCollection?: boolean;
  readyForCollectionAt?: Date;
  readyForCollectionBy?: IUser;
  receivedProcessingAt: Date;
  receivedProcessingBy: IUser;
  receivedProcessingStatus: string;
  referringDocuments: IRelatedDocument[];
  relatedDocuments: IRelatedDocument[];
  relatedProviderOrders: IProviderOrder[];
  services: IAdditionalService[];
  shippingAlert?: boolean;
  shippingAlertAt?: Date;
  shippingAlertBy?: IUser;
  shippingAlertMessage?: string;
  shippingCost?: number;
  shippingCostCurrency: string;
  shippingOrderNumber?: string;
  sourceAddress?: IAddress | null;
  state: string;
  trackingCheckedAt?: string;
  trackingLink?: string;
  trackingNumber?: string;
  trackingRecords: ITrackingRecord[];
  noInvoiceRequired: boolean;
  noInvoiceRequiredAt: string | null;
  noInvoiceRequiredBy: {
    id: number;
    name: string;
  } | null;
  noInvoiceRequiredComment: string | null;
  customsDocumentsStatus: CustomsDocumentsStatus;
  masterShippingId?: number | null;
  directionFromEndProvider?: boolean;
  directionToEndCustomer?: boolean;
  sourceProviderOrderId: number | null;
  updatedAt: string;
}

export interface IStorageParams {
  storageType: StorageType;
  processingState?: string;
  location: string;
}

export interface ITrackingRecord {
  id: number;
  comment: string;
  createdAt: string;
  data: Record<string, any>;
  trackingEvent: string;
}

export interface IUser {
  id: number;
  email?: string;
  name: string;
}

export interface ProviderOrderOption extends ProvideOrderSource {
  disabled?: boolean;
}
