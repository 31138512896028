import { Rule } from 'antd/lib/form';
import { alphanumericDotRegex, alphanumericRegex } from 'collections/regexes';

import { REQUIRED } from './messages';

export const required = (value = true): Rule => ({
  required: value,
  message: REQUIRED,
});

export const trimmedRequired = (value = true): Rule => ({
  required: value,
  whitespace: true,
  message: REQUIRED,
});

export const alphanumericOnly = (): Rule => ({
  pattern: alphanumericRegex,
  message: 'Only alphanumeric characters are allowed',
});

export const alphanumericDotOnly = (): Rule => ({
  pattern: alphanumericDotRegex,
  message: 'Only alphanumeric characters, dots and spaces are allowed',
});
