import { Money } from '__generated__/types';
import { IMoney } from '@xometry/ui';
import { Address, ICachedXometryVatNumber, PartData, PreloadedFile } from 'interfaces';
import { IProvider, IProviderContact } from 'interfaces/graphql';

export interface FetchedPositionForII {
  id: number;
  accountingCategory: string;
  name: string;
  quantity: number;
  part?: PartData;
  pricePerPiece: number;
  pricePerPieceMoney: Money;
  partId?: number;
  vatRate?: number;
  refSource?: string | null;
  refType: string;
  refId: number;
}

export interface PositionIncInvoice extends FetchedPositionForII {
  position: number;
  providerPrice: number;
  vatRate: number;
  id: number;
}

export interface PositionII {
  id?: number;
  _destroy?: boolean;
  key: string;
  categoryName: string;
  name: string;
  quantity: number;
  price: number;
  priceMoney?: Money | IMoney;
  part?: PartData;
  vat?: number;
  position: number;
  partId?: number;
  refId?: number;
  refSource?: string | null;
  refType?: string;
}

export enum IncomingInvoiceState {
  Canceled = 'canceled',
  Draft = 'draft',
  Published = 'published',
  SentToMikrojump = 'sent_to_mikrojump',
}

export enum Validity {
  PartnerReady = 'partner_ready',
  ManagerReady = 'manager_ready',
  FinanceReady = 'finance_ready',
  PartnerNeedFix = 'partner_need_fix',
  ManagerNeedFix = 'manager_need_fix',
  RejectedByPm = 'rejected_by_pm',
}

export interface ChangeBy {
  by: string;
  at: string;
}

export type IncomingInvoiceProvider = Pick<IProvider, 'id' | 'vatId' | 'legalName'>;

export interface IncomingInvoiceProviderContact extends Pick<IProviderContact, 'id' | 'phone' | 'email'> {
  name: string;
}

export interface IncomingInvoiceBankDetails {
  bankCountry: string;
  bankAccountHolderName: string;
  iban: string;
  name: string;
  swiftbic: string;
}

export interface IncomingInvoice {
  amountLeft: string;
  amountLeftMoney: IMoney;
  id: number;
  dealId: string;
  dealValueMoney: IMoney;
  number: string;
  dealProviderOrderId: number;
  refInvoiceId?: number;
  refInvoiceDated?: string;
  creditNote: boolean;
  selfBilling: boolean;
  isStorno: boolean;
  isPartial: boolean;
  isPublishedInPa: boolean;
  state: IncomingInvoiceState;
  holdUntil?: string;
  issuedAt: string;
  paymentDueDate?: string;
  paidAt?: string;
  registeredAt?: string;
  comment?: string;
  datevComment?: string;
  intacctComment?: string;
  needFixComment?: string;
  overrideAccountingDataDisabled?: boolean;
  provider: IncomingInvoiceProvider;
  providerContact: IncomingInvoiceProviderContact;
  providerBankDetails: IncomingInvoiceBankDetails;
  providerBillingAddress: Address;
  attachedFiles: PreloadedFile[];
  invoicePositions: PositionIncInvoice[];
  validity: Validity;
  invoicePrepared?: ChangeBy;
  invoiceReady?: ChangeBy;
  invoiceChecked?: ChangeBy;

  totalAmountNetto: number;
  totalAmountBrutto: number;
  vatAmount: number;
  vatAvg: number;
  vatRate: number;

  sachkontoNumber?: string;
  datevVatCode?: string;
  xometryVatNumber?: ICachedXometryVatNumber;
  vatPdfCorrectionRequired?: boolean;
}
