import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { TemplateFragmentDoc } from './template';
import { AttachmentFragmentFragmentDoc } from './attachmentsFragment';
import { PrepareAttachmentFragmentFragmentDoc } from './prepareAttachmentFragment';
export type RenderedTemplateFragment = { content: string, readOnly: boolean, blindCarbonCopy?: Array<string> | null, carbonCopy?: Array<string> | null, recipients?: Array<string> | null, id: string, name?: string | null, constantName?: string | null, locale: Types.TemplateLocaleEnum, attachments: Array<{ id: string, uuidId: string, name: string, previewUrl?: string | null, previewLargeUrl?: string | null }>, documents: Array<{ id: string, uuidId: string, name: string, previewUrl?: string | null, previewLargeUrl?: string | null }>, prepareAttachments: Array<{ name: string, uuid: string, value: string }> };

export const RenderedTemplateFragmentDoc = gql`
    fragment renderedTemplate on RenderedTemplate {
  ...template
  attachments {
    ...attachmentFragment
  }
  documents {
    ...attachmentFragment
  }
  prepareAttachments {
    ...prepareAttachmentFragment
  }
  content
  readOnly
  blindCarbonCopy
  carbonCopy
  recipients
}
    ${TemplateFragmentDoc}
${AttachmentFragmentFragmentDoc}
${PrepareAttachmentFragmentFragmentDoc}`;