import { Money, ProviderOrdersSamplesConfirmationStatusEnum } from '__generated__/types';
import { IMoney } from '@xometry/ui';
import { Claim } from 'interfaces/graphql';
import { OverviewQuery } from 'utils/graphql/queries/__generated__/overview';

import { OrgGrade } from './OrgStore';

export enum DealPipedriveStatus {
  won = 'won',
  open = 'open',
  lost = 'lost',
  deleted = 'deleted',
}

export interface CreateDealFormValues {
  createEmpty: boolean;
  attachments: unknown[];
  personId: number;
}

export enum DealTypeClaim {
  Customer = 'customer',
  Xometry = 'shift',
}

export enum CertificateName {
  Compliance21 = 'cert_compliance_2_1',
  Compliance22 = 'cert_compliance_2_2',
  Materials31 = 'cert_materials_3_1',
  Materials32 = 'cert_materials_3_2',
  MaterialsPlastic = 'cert_materials_plastic',
  HeatTreatment = 'cert_heat_treatment',
  Rohs = 'cert_rohs',
  Reach = 'cert_reach',
}

export interface CertificateData {
  positions: number;
  amount: number;
  amountMoney: Money;
}

export type Certificates = {
  [key in CertificateName]?: CertificateData;
};

export type PartsOverview = OverviewQuery['overview'];

export interface QDeal {
  deal: IDeal;
}

export interface DealPayment {
  amountBrut: number;
  amountBrutMoney: IMoney;
  createdAt: string;
  id?: number;
  isRefund: boolean;
  number: string;
  paidAt: string;
  state: string;
  invoiceRelations: {
    createdAt: string;
    creditNote: {
      id: number;
      totalAmountBrutto: number;
      totalAmountBruttoMoney: IMoney;
    } | null;
    date: string;
    id: number;
    outcomingInvoice: {
      creditNote: {
        id: number;
        issuedAt: string;
        number: number;
        totalAmountBrutto: number;
        totalAmountBruttoMoney: IMoney;
      };
      id: number;
      issuedAt: string;
      number: number;
      totalAmountBrutto: number;
      totalAmountBruttoMoney: IMoney;
    };
    payment: {
      amountBrut: number;
      amountBrutMoney: IMoney;
      id: number;
      number: string;
      paidAt: string;
      refunds: {
        amountBrut: number;
        amountBrutMoney: IMoney;
        id: number;
        state: string;
      }[];
      registeredAt: string | null;
    };
    refund: {
      id: number;
      amountBrut: number;
      amountBrutMoney: IMoney;
    } | null;
    registeredAt: string | null;
    totalAmountBrutto: number;
    totalAmountBruttoMoney: IMoney;
    updatedAt: string;
  }[];
  refunds: {
    amountBrut: number;
    amountBrutMoney: IMoney;
    id: number;
    state: string;
  }[];
}

export interface DealOutcomingInvoice {
  amountBrut: number;
  amountBrutMoney: IMoney;
  createdAt: string;
  creditNote: {
    id: number;
    totalAmountBrutto: number;
    totalAmountBruttoMoney: IMoney;
  };
  id?: number;
  issuedAt: string;
  number: string;
  paidAt: string;
  refInvoice: {
    id: number;
  };
  state: string;
  paymentRelations: {
    createdAt: string;
    creditNote: {
      id: number;
      totalAmountBrutto: number;
      totalAmountBruttoMoney: IMoney;
    } | null;
    date: string;
    id: number;
    outcomingInvoice: {
      id: number;
      issuedAt: string;
      number: number;
      totalAmountBrutto: number;
      totalAmountBruttoMoney: IMoney;
    };
    payment: {
      amountBrut: number;
      amountBrutMoney: IMoney;
      id: number;
      isRefund: boolean;
      number: string;
      paidAt: string;
      refunds: {
        amountBrut: number;
        amountBrutMoney: IMoney;
        id: number;
        state: string;
      }[];
      registeredAt: string | null;
    };
    refund: {
      id: number;
    } | null;
    registeredAt: string | null;
    totalAmountBrutto: number;
    totalAmountBruttoMoney: IMoney;
    updatedAt: string;
  }[];
  refunds: {
    amountBrut: number;
    amountBrutMoney: IMoney;
    id: number;
    state: string;
  }[];
  totalAmountBrutto: number;
  totalAmountBruttoMoney: IMoney;
}

export interface IDeal {
  id: number;
  name?: string;
  number?: string;
  vat: number;
  enquiryId?: number;
  currency: string;

  grade?: OrgGrade;

  pmId?: number;
  pipedriveId?: string;
  pipedriveStatus: DealPipedriveStatus;
  pipedrivePipeline: string;
  stage: string;

  externalCrmId?: string;
  externalCrmUrl?: string;

  pipedriveUrl: string;
  googleDriveUrl: string;
  slackUrl: string;
  slackUrlRequested: string;

  isNpg: boolean;
  originalDeals?: {
    id: number;
  }[];

  testDeal: boolean;

  orderConfirmation?: {
    shippingAddress?: {
      country: string;
    };
    comment?: string;
    lots: Array<{
      id: string;
      quantity: number;
      part: {
        id: string;
        qtyOrdered: number;
        qtyInProduction: number;
        qtyShipped: number;
        qtyDelivered: number;
        qtyClaim: number;
      };
    }>;
  };

  ocPaymentDue?: number;
  isExpress?: boolean;
  isForSoftwareTesting: boolean;
  isPreQuoted?: boolean;
  isOnHold: boolean;
  isImportant: boolean;
  measurementProtocols?: string[];

  isSamplesNeeded?: boolean;
  samplesConfirmationStatus?: ProviderOrdersSamplesConfirmationStatusEnum;

  manualProcessingFinance: boolean;
  manualProcessingJobs: boolean;
  manualProcessingEmails: boolean;
  manualProcessingOutcomingInvoices: boolean;
  manualProcessingComment?: string;
  manualProcessingOrderConfirmation?: boolean;
  manualProcessingShippings?: boolean;
  manualProcessingStages?: boolean;

  createdAt?: string;

  customerOrderDate?: string;
  purchaseOrderDate?: string;
  deliveryDeadline?: string;
  pipedriveOrderValue?: number;
  totalPaidNett?: number;
  invoiced?: string;
  reasonOfLose?: string;

  productionManager: {
    id: number | undefined;
    name: string | undefined;
  };

  customerOrderCode?: string;
  frameworkAgreement?: boolean;

  owner: {
    name?: string;
    id?: number;
  };

  utm: {
    campaign: string;
    medium: string;
    term: string;
  };

  outcomingInvoices: DealOutcomingInvoice[];
  payments: DealPayment[];

  claims?: Claim[];

  personId: number;
  organizationId: number;

  certificates?: Certificates;

  isHighVolume: boolean;
}
