import { LoadingOutlined } from '@ant-design/icons';
import { EAttachmentStatus, InlineFilePreviewView } from '@xometry/ui';
import { Button, Form, FormInstance, Spin, Upload } from 'antd';
import { ReactComponent as ArrowCircleIcon } from 'assets/images/icons/arrow-circle.svg';
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross.svg';
import clsx from 'clsx';
import {
  StateAttachment,
  TemplateModalFormFieldEnum,
  TemplateModalFormValues,
} from 'components/TemplatesModal/TemplateModal.types';
import React, { FC } from 'react';

import s from './TemplatesAttachments.module.less';
import { useAttachments } from './useAttachments';

interface Props {
  attachments: StateAttachment[];
  setAttachments: React.Dispatch<React.SetStateAction<StateAttachment[]>>;
  form: FormInstance<TemplateModalFormValues>;
  readOnly: boolean;
  isMobile: boolean;
}

export const TemplatesAttachmentsComponent: FC<Props> = ({ attachments, setAttachments, form, readOnly, isMobile }) => {
  const {
    currentStartIndex,
    disabledRow,
    handleBeforeUpload,
    handleNextClick,
    handlePrevClick,
    handleRemoveAttachment,
    showCarousel,
    visibleAttachments,
  } = useAttachments({
    attachments,
    setAttachments,
    form,
    isMobile,
  });

  const mods = { [s.isMobile]: isMobile };

  return (
    <div className={s.attachments}>
      <div className={clsx(s.attachmentsList, { ...mods })}>
        {showCarousel ? (
          <div
            className={clsx(s.circleArrow, {
              [s.disabled]: currentStartIndex === 0,
              [s.isLeft]: true,
            })}
          >
            <ArrowCircleIcon onClick={handlePrevClick} />
          </div>
        ) : null}

        {visibleAttachments.length
          ? visibleAttachments.map((attachment) => (
              <div className={s.attachmentWrapper} key={attachment.id}>
                <Spin spinning={attachment.status === EAttachmentStatus.UPLOADING} indicator={<LoadingOutlined />}>
                  <InlineFilePreviewView
                    key={attachment.id}
                    id={attachment.id}
                    uuid={attachment.uuidId || attachment.id}
                    name={attachment.name}
                    previewImageUrl={attachment.previewUrl || attachment.previewLargeUrl || undefined}
                    status={attachment.status}
                  />
                </Spin>
                <div
                  className={s.circleCross}
                  role="button"
                  tabIndex={0}
                  onClick={() => handleRemoveAttachment(attachment.id)}
                >
                  <CrossIcon />
                </div>
              </div>
            ))
          : null}
        {showCarousel ? (
          <div
            className={clsx(s.circleArrow, {
              [s.disabled]: disabledRow,
            })}
          >
            <ArrowCircleIcon onClick={handleNextClick} />
          </div>
        ) : null}
      </div>
      {readOnly ? null : (
        <Form.Item className={s.formUploadItem} name={TemplateModalFormFieldEnum.Attachments} shouldUpdate>
          <Upload
            name={TemplateModalFormFieldEnum.Attachments}
            multiple
            showUploadList={false}
            beforeUpload={handleBeforeUpload}
          >
            <Button className={s.uploadButton} key="send" type="default" size="middle">
              Upload Files
            </Button>
          </Upload>
        </Form.Item>
      )}
    </div>
  );
};

export const TemplatesAttachments = React.memo(TemplatesAttachmentsComponent);
