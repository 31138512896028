import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type PrepareAttachmentFragmentFragment = { name: string, uuid: string, value: string };

export const PrepareAttachmentFragmentFragmentDoc = gql`
    fragment prepareAttachmentFragment on PrepareAttachment {
  name
  uuid
  value
}
    `;