import { Notification } from 'components/UI';
import { useCallback, useState } from 'react';
import { routesApi } from 'routes/api';
import { requestBuilder } from 'utils/axios';

import { handleDownloadError } from './shared/downloadUtils';

export const downloadFile = (file: Blob, filename: string) => {
  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);
  link.onclick = (e) => {
    e.stopImmediatePropagation();
  };

  document.body.appendChild(link);

  link.click();
};

export const downloader = () => (url: string, filename: string) => {
  const request = requestBuilder({ responseType: 'blob' });

  return request
    .get(url)
    .then((response) => {
      const file = new Blob([response.data]);
      downloadFile(file, filename);
    })
    .catch(handleDownloadError);
};

export default downloader;

export const useGroupDownload = () => {
  const download = useCallback((uuidIds: string[], filename?: string) => {
    const request = requestBuilder({ responseType: 'blob' });
    const url = routesApi.groupFileDownload(uuidIds, filename);

    return request
      .get(url)
      .then((response) => {
        const file = new Blob([response.data]);

        let resultFilename = `${filename || 'attachments'}.zip`;
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const parsedFilename = (response.headers['content-disposition'] as string)
            ?.split(';')[1]
            ?.split('filename=')[1]
            ?.replace(/['"]+/g, '');

          if (typeof parsedFilename === 'string') {
            resultFilename = parsedFilename;
          }
        } catch {
          console.error('Failed to parse the content-disposition header.');
        }

        downloadFile(file, resultFilename);
      })
      .catch(handleDownloadError);
  }, []);

  return { download };
};

export const useDownloadWithLoader = (url: string, filename: string) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const download = useCallback(() => {
    if (isDownloading) return;

    setIsDownloading(true);

    return requestBuilder({ responseType: 'blob' })
      .get(url)
      .then((response) => {
        const file = new Blob([response.data]);
        downloadFile(file, filename);
      })
      .catch(handleDownloadError)
      .finally(() => setIsDownloading(false));
  }, [isDownloading, filename, url]);

  return { setIsDownloading, isDownloading, download };
};

export const useDownload = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const download = useCallback(
    (url: string, filename: string) => {
      if (isDownloading) return;

      setIsDownloading(true);

      return requestBuilder({ responseType: 'blob' })
        .get(url)
        .then((response) => {
          const file = new Blob([response.data]);
          downloadFile(file, filename);
        })
        .catch(handleDownloadError)
        .finally(() => setIsDownloading(false));
    },
    [isDownloading],
  );

  return { isDownloading, download };
};

export const usePostDownload = () => (url: string, filename: string, data: any) => {
  const request = requestBuilder({ responseType: 'blob' });

  return request
    .post(url, data)
    .then((response) => {
      const file = new Blob([response.data]);
      downloadFile(file, filename);
    })
    .catch(handleDownloadError);
};

export const useExternalDownload = () => (url: string, filename: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

export const useDownloadMultipleFiles = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const download = useCallback(
    (fileList: { url: string; name: string }[]) => {
      if (isDownloading) return;

      setIsDownloading(true);

      const request = requestBuilder({ responseType: 'blob' });

      const requests = fileList.map(({ url }) => request.get(url));

      return Promise.allSettled(requests)
        .then((responses) => {
          responses.forEach((response) => {
            const fileSource = fileList[responses.indexOf(response)];
            const filename = fileSource.name;

            if (response.status === 'rejected') {
              Notification.Error(`Failed to download ${filename}`);

              return;
            }

            if (response.status === 'fulfilled') {
              const file = new Blob([response.value.data]);
              downloadFile(file, filename);
            }
          });
        })
        .catch(() => {
          Notification.Error('Something went wrong', 'Failed to download files');
        })
        .finally(() => setIsDownloading(false));
    },
    [isDownloading],
  );

  return { isDownloading, download };
};
